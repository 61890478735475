import theme from './theme';
export { theme }; // Wallet Constants

export const RESERVATION_CHECKOUT = 'RESERVATION_CHECKOUT';
export const BUY_TOKENS = 'BUY_TOKENS'; // Reservation Types

export const NO_TYPE = "NO_TYPE";
export const DRIVER_RESERVATION = "DRIVER_RESERVATION";
export const OWNER_RESERVATION = "OWNER_RESERVATION";
export const UNAVAILABLE = "UNAVAILABLE"; // Plug Constants

export const AC = "AC";
export const DC = "DC";
export const AC_DC = [AC, DC]; // OCPP Versions

export const NO_VERSION = "-";
export const VERSION_1_6 = "1.6";
export const VERSION_2_0_0 = "2.0.0";
export const VERSION_2_0_1 = "2.0.1";
export const OCPP_VERSIONS = [NO_VERSION, VERSION_1_6, VERSION_2_0_0, VERSION_2_0_1]; // OCPP Statuses

export const OCPP_AVAILABLE = "Available";
export const OCPP_PREPARING = "Preparing";
export const OCPP_CHARGING = "Charging";
export const OCPP_SUSPENDED_EVSE = "SuspendedEVSE";
export const OCPP_SUSPENDED_EV = "SuspendedEV";
export const OCPP_FINISHING = "Finishing";
export const OCPP_RESERVED = "Reserved";
export const OCPP_UNAVAILABLE = "Unavailable";
export const OCPP_FAULTED = "Faulted"; // Use not connected instead of Unavailable

export const OCPP_NOT_CONNECTED = "Not connected"; // Custom OCPP Charging session states

export const CHARGING_NOT_STARTED = 0;
export const CHARGING_STARTED_PENDING = 1;
export const CHARGING_STARTED_CONFIRMED = 2;
export const CHARGING_STOPPED_PENDING = 3;
export const CHARGING_STOPPED_CONFIRMED = 4; // Info Codes For Charging Now

export const LOGIN_REQUIRED = 0;
export const AVAILABLE_NOT_CONNECTED = 1;
export const CURRENTLY_DISCONNECTED = 2;
export const SUPPORTED_LANG = ["el", "en"]; // Pricing Policies

export const ENERGY_BASED_PRICING = 0;
export const TIME_BASED_PRICING = 1;
export const HYBRID_PRICING_POLICY = 2; // Notification Categories Constants

export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const MARKETING = "MARKETING";
export const REMINDER = "REMINDER";
export const INFO = "INFO";
export const STARTED = "STARTED";
export const STOPPED = "STOPPED";
export const WARNING = "WARNING";
export const UNPLUGGED = "UNPLUGGED"; // Currencies

export const EURO = "EURO";
export const EURO_SYMBOL = "€";
export const US_DOLLAR = "US_DOLLAR";
export const US_DOLLAR_SYMBOL = "$";
export const BRITISH_POUND = "BRITISH_POUND";
export const BRITISH_POUND_SYMBOL = "£";