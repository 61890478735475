import { keep2Decimals } from "./pricing";
import { reservationHasPassed } from "./reservations";
import moment from "moment";
import { TIME_BASED_PRICING } from "../constants";
/**
 * Return power values in KW of the plugs of the specified station,
 * in descending order
 * @param {Object} station 
 */

export const handleUniquePlugsKW = station => {
  let unique_plugs = [];
  station.plugs.forEach(plug => {
    if (unique_plugs.indexOf(plug.power_KW) <= -1) {
      unique_plugs.push({
        power: plug.power_KW,
        ac_dc: plug.ac_dc
      });
    }
  });
  unique_plugs.sort((a, b) => b - a);
  return unique_plugs;
};
/**
 * Return the minimum cost of all plugs of the specified station
 * @param {Object} station 
 */

export const minCostPlugs = station => {
  let costs = station.plugs.map(plug => {
    let price_per_kWh = 0;

    if (plug.pricing_policy === TIME_BASED_PRICING) {
      if (plug.power !== 0) {
        price_per_kWh = parseFloat(plug.base_price) * 60 / plug.power_KW;
      }
    } else {
      price_per_kWh = plug.price_per_kWh;
    }

    return price_per_kWh;
  });
  costs.sort((a, b) => a > b ? 1 : -1);
  return keep2Decimals(costs[0]); // return costs[0];
};
/**
 * @deprecated
 * Return a dictionary with the hours and mins till next reservation
 * for a particular plug
 * 
 * @param {Array} plug_reservations
 */

export const nextReservation = plug_reservations => {
  if (plug_reservations) {
    let current_date = new Date();
    let next_res_date = new Date(plug_reservations.start_time);
    const reservationIsToday = moment(current_date).format("DD/MM/YYYY") == moment(next_res_date).format("DD/MM/YYYY");
    const reservationInPast = reservationHasPassed(plug_reservations);

    if (!reservationInPast) {
      let time_diff = next_res_date.getTime() - current_date.getTime();
      let time_diff_min = Math.floor(time_diff / (1000 * 60));
      let time_diff_h = Math.floor(time_diff / (1000 * 60 * 60));

      if (time_diff_min >= 0 && time_diff_h >= 0) {
        time_diff_min = Math.floor(time_diff_min - time_diff_h * 60);
      }

      return {
        hours: time_diff_h,
        min: time_diff_min
      };
    }
  } else {
    return {
      hours: 12,
      min: 0
    };
  }
};
/**
 * 
 * @param {Array} plugs 
 * @param {String} plugType 
 */

export const filterPlugsByType = (plugs, plugType) => {
  return plugs.filter(plug => plug.type === plugType);
};
/**
 * Preselect a Plug:
 *  - If no type is specified, select first Plug with status "Available" and that does not require approval (for charge now)
 *  - else select first Plug with just status "Available"
 *  - If type is specified select first Plug of that type with status "Available". If
 *    there is no such plug return first Plug of that type
 *  - If no Plug is available, select first one from the list
 * @param {Array} plugs 
 * @param {Array} plugStatuses 
 * @param {String} plugType, optional
 */

export const defaultPlugPreselection = (plugs, plugStatuses, plugType = null) => {
  if (plugs.length > 0) {
    if (plugType) {
      // TODO What do we do here?
      const filteredPlugs = filterPlugsByType(plugs, plugType);
    } else {
      // Caution this may not be real time!
      const availablePlugForChargingNow = plugs.find(plug => {
        const isAvailable = plug.ocpp_status === "Available";
        const noApproval = plug.requires_approval === false;
        return isAvailable && noApproval;
      });
      const availablePlugStatus = plugStatuses.find(plug => plug.status === "Available");

      if (availablePlugForChargingNow) {
        // Return first plug that is both available and does not require approval
        const plugId = availablePlugForChargingNow.id;
        const plugToSelect = plugs.find(plug => plug.id === plugId);
        return plugToSelect;
      }

      if (availablePlugStatus) {
        // Return first plug that is just available
        const plugId = availablePlugStatus.id;
        const plugToSelect = plugs.find(plug => plug.id === plugId);

        if (plugToSelect) {
          return plugToSelect;
        }
      } // No plug with status available was found, so the first one is returned


      return plugs[0];
    }
  }

  return null;
};
/**
 * Get the OCPP Status of a specific plug from a list of plug statuses
 * @param {Array} plugStatuses List of plugs' statuses to search
 * @param {String} plugId The plug ID of interest
 */

export const getPlugOcppStatus = (plugStatuses, plugId) => {
  const result = plugStatuses.find(plug => plug.id === plugId);

  if (result !== undefined) {
    return result.status;
  } else {
    return null;
  }
};