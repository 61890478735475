import React from 'react';
import Head from "next/head";

export default function DefaultMetadata(props) {
  const title = props.title ? props.title : "EV Loader | Σταθμοί Φόρτισης Ηλεκτρικών Αυτοκινήτων";
  const description = props.description ? props.description : "Βρες που θα φορτίσεις το ηλεκτρικό όχημα σου γρήγορα";

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="og:title" content={title} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content="https://evloader.com/" />
      <meta name="og:site_name" content="EV LOADER" />
      <meta name="og:image" content="http://evloader.com/images/gallery_image.jpg" />
      <meta name="og:description" content={description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content="https://evloader.com/" />
      <meta name="twitter:title" content={title}/>
      <meta name="twitter:description" content={description}/>
      <meta name="twitter:image" content="http://evloader.com/images/gallery_image.jpg"/>
    </Head>
  )
}