const primaryOrange = '#f18d0f';
export default {
  primaryOrange: primaryOrange,
  tabIconDefault: '#ccc',
  tabIconSelected: primaryOrange,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: primaryOrange,
  noticeText: '#fff',
  darkGray: '#4e4e4e',
  startGreen: '#00CD8B',
  stopRed: '#FF563D'
};