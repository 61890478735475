import React from "react";
import axios from "axios";
import { API_URL, APP_ENV } from "@js-shared/lib/config/environment";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@components/Link";
import { connect } from "react-redux";
import useWindowSize from "hooks/useWindowSize";
import {
  fetchRecentStations,
  fetchFeaturedStations,
} from "@js-shared/lib/actions/stationActions";
import { Container, Box } from "@material-ui/core";
import { handleUniquePlugsKW } from "@js-shared/lib/utils/plugs";
import PublicPage from "@components/PublicPage";
import { useTranslation } from "react-i18next";
import Image from "next/image";
import dynamic from "next/dynamic";
import { getPageSeoStaticProps } from "@utils/seo";
import DefaultMetadata from "@components/DefaultMetadata";
import { useRouter } from "next/router";

// Dynamic imports
const GoogleMapsSearch = dynamic(() => import("@components/GoogleMapsSearch"));
const StationCard = dynamic(() => import("@components/StationCard"));
const Footer = dynamic(() => import("@components/Homepage/Footer"));
const Ribbon = dynamic(() => import("@components/Homepage/HomepageRibbon"));
const Featured = dynamic(() => import("@components/Homepage/FeaturedStations"));
const MobileBanner = dynamic(() => import("@components/Homepage/MobileBanner"));
const CollabRibbon = dynamic(() => import("@components/Homepage/CollabRibbon"));
const SupportedChargers = dynamic(() => import("@components/Homepage/SupportedChargers"));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "80vh",
    // marginTop: "-64px",
     
  },
  paper: {
    // paddingTop: theme.spacing(18),
    margin: theme.spacing(0, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 0px 0px 0px",
    // backgroundColor: "#fafafa",
  },
  paper2: {
    margin: theme.spacing(1, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 0px 0px 0px",
    // backgroundColor: "#fafafa",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "white",
  },
  form: {
    paddingLeft: theme.spacing(0),
    marginTop: theme.spacing(5),
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing(2),
    height: "54px",
    // borderRadius: "34px",
  },
  imageContainer: {
    margin: 0,
    position: "relative",
    // height: "80vh",
    margin: "auto",
    textAlign: "center"
  },
  image: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0) 75%, rgba(0,0,0,0.85) 100%)",
    backgroundRepeat: "no-repeat",
    backgroundColor: "transparent",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "760px",
  },
  featured_container: {
    display: "flex",
    maxWidth: "1450px",
    flexDirection: "column",
  },
  featured_list: {
    width: "100%",
    backgroundColor: "#f3f4f5",
    overflow: "hidden",
    justifyContent: "space-around",
  },
  featuredListContainer: {
    
    width: "100%",
    backgroundColor: "#f3f4f5",
    overflow: "hidden",
    justifyContent: "space-around",
  },
  list_container: {
    flexWrap: "nowrap",
    WebkitScrollSnapType: "none",
  },
}));

function Welcome(props) {
  const classes = useStyles();
  const size = typeof window !== "undefined" ? useWindowSize() : { width: 0 };
  const { t } = useTranslation();
  const router = useRouter();

  React.useEffect(() => {
    if (props.user.isAuthenticated) {
      props.fetchRecentStations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getResponsiveColumn = () => {
    if (size.width < 575) {
      return 1;
    } else if (size.width < 810) {
      return 2;
    } else if (size.width < 1100) {
      return 3;
    } else if (size.width < 1380) {
      return 4;
    } else {
      return 5;
    }
  };

  return (
    <PublicPage>
      <DefaultMetadata />

      <Grid
        container
        component="main"
        className={classes.root}
        style={{ backgroundColor: "#fafafa" }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              component={Paper}
              elevation={6}
              square
              style={{
                boxShadow: "0 0px 0px 0px",
                backgroundColor: "#fafafa",
              }}
            >
              <div className={classes.paper}>
                <div
                  className={size.width > 600 ? classes.form : null}
                  noValidate
                >
                  <Box mt={10}>
                    {/* <Box mt={28}> */}
                    <Grid container>
                      {/* <Typography component="h1" variant="h5">
                        <p style={{ color: "rgba(0, 0, 0, 0.7)" }}>
                          {t("Βρες που θα φορτίσεις το αυτοκίνητό σου σε 1'")}
                        </p>
                      </Typography> */}

                      <Typography
                        variant="h1"
                        style={{
                          color: "rgba(0, 0, 0, 0.7)",
                          fontSize: "50px",
                          fontWeight: 600,
                          lineHeight: "60px",
                        }}
                      >
                        {t("Βρες που θα φορτίσεις το αυτοκίνητό σου σε 1'")}
                      </Typography>
                      <p
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontSize: "20px",
                        }}
                      >
                        {t(
                          "Ή βρες σταθμό φόρτισης κοντά στην τρέχουσα τοποθεσία σου "
                        )}
                      </p>

                      <Grid container spacing={1}>
                        <Grid item xs={12} md={8}>
                          <GoogleMapsSearch
                            label={t("Περιοχή")}
                            onGeolocationHandling={() =>
                              router.push("/stations-map")
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Button
                            type="submit"
                            fullWidth
                            disableElevation
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            component={Link}
                            href="/stations-map"
                            naked
                            startIcon={<SearchIcon />}
                          >
                            {t("Αναζήτηση")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </div>

              {/* Here add the StationCard Component with the recent reservations of the user */}
              {props.user.user && (
                <div className={classes.paper2}>
                  <div className={size.width > 600 ? classes.form : null}>
                    <Typography component="h2">
                      <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                        {t("Πρόσφατοι σταθμοί")}
                      </p>
                    </Typography>
                    <Container
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        overflow: "auto",
                        showsHorizontalScrollIndicator: "false",
                        paddingLeft: 0,
                        // backgroundColor: "transparent",
                      }}
                    >
                      {props.stations.latest_stations.map((station) => (
                        <div
                          key={`latest-station-${station.id}`}
                          style={{
                            marginBottom: "20px",
                            marginTop: "10px",
                          }}
                        >
                          <StationCard
                            station={
                              props.stations.fetched_stations[
                                props.stations.fetched_stations.findIndex(
                                  (obj) => obj.id === station.id
                                )
                              ]
                            }
                            className={classes.card}
                            orientation="vertical"
                          />
                        </div>
                      ))}
                    </Container>
                  </div>
                </div>
              )}
            </Grid>

            {size.width > 600 && (
              <Grid
                item
                xs={false}
                sm={6}
                md={6}
                className={classes.imageContainer}
                style={{ margin: 0 }}
              >
                <div>
                  <object
                    type="image/svg+xml"
                    alt="electric vehicle charging animation"
                    data="/illustrations/electric-car-animate.svg"
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </Container>

        <Box className={classes.featuredListContainer}>
          <Container maxWidth="lg">
            <Grid item xs={12}>
              <div className={classes.featured_list}>
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "35px",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography
                      gutterBottom
                      component="h2"
                      variant="h5"
                      style={{
                        alignSelf: "center",
                        marginBottom: "20px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {t("Επιλεγμένοι Σταθμοί")}
                    </Typography>
                  </div>
                  <GridList
                    className={classes.list_container}
                    cols={getResponsiveColumn()}
                    cellHeight="auto"
                  >
                    {props.featuredStations.map((station) => (
                      <GridListTile
                        key={station.id}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Featured
                          station={station}
                          unique_plugs={handleUniquePlugsKW(station)}
                        />
                      </GridListTile>
                    ))}
                  </GridList>
                </>
              </div>
            </Grid>
            {/* fd */}
          </Container>
        </Box>

        {/* Mobile Banner Component */}
        <Grid item xs={12}>
          <MobileBanner version={size.width > 600 ? "mobile" : "web"} />
        </Grid>

        {/* Homepage Ribbon Component */}
        <Grid item xs={12}>
          <Ribbon />
        </Grid>

        <Grid item xs={12}>
          <Container maxWidth="lg">
            <CollabRibbon />
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Container maxWidth="lg">
            <SupportedChargers />
          </Container>
        </Grid>
        {/* Sample for Grid List in the X-Axis */}

        {/* Homepage Footer Component */}
        <Footer />
      </Grid>
    </PublicPage>
  );
}

export async function getStaticProps() {
  let featuredStations = [];
  try {
    // Cancel request of featured-stations in case that request takes too long to load 
    // or URL is unreachable (e.g. in Gitlab while building staging image which is behind a firewall)
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setTimeout(() => {
      source.cancel()
    }, 2000);
    const featuredStationsResponse = await axios.get(`${API_URL}/api/stations/featured-stations/`, {
      cancelToken: source.token
    });
    featuredStations = featuredStationsResponse.data.featured_stations
  } catch (error) {}

  const seoProps = await getPageSeoStaticProps("/");
  return {
    props: {
      ...seoProps.props,
      featuredStations,
    },
    revalidate: seoProps.revalidate,
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    stations: state.stations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRecentStations: () => dispatch(fetchRecentStations()),
    fetchFeaturedStations: () => dispatch(fetchFeaturedStations()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
