import moment from "moment";
import { getDurationInMinutes } from "./datetime";
/**
 * Get the upcoming reservations from a list of reservations
 * @param {Array} reservations 
 */

export const filterByUpcomingReservations = reservations => {
  return reservations.filter(reservation => reservation.stop_time > moment().format()).sort((a, b) => a.start_time > b.start_time);
};
/**
 * @deprecated
 * Get next upcoming reservation (within 5 minutes)
 * @param {Array} reservations 
 */

export const getNextUpcomingReservation = reservations => {
  const firstUpcomingReservation = filterByUpcomingReservations(reservations)[0];

  if (firstUpcomingReservation) {
    // Time from now to next upcoming reservation in minutes
    const timeUntilReservation = getDurationInMinutes(firstUpcomingReservation.start_time, new Date());

    if (timeUntilReservation <= 5) {
      return firstUpcomingReservation;
    }
  }

  return null;
};
/**
 * 
 * Return a String with the error message to Display on Charge Now
 * Component depending on state
 */

export const chargeNowChecks = (user, reservationCap, plugStatus) => {
  if (!user) {
    return "In order to charge now you need to be logged in.";
  } else if (reservationCap['min'] < 0 || reservationCap['hours'] < 0) {
    return "This plug is currently reserved. You can book future reservation or try again later.";
  } else if (plugStatus == "Unavailable") {
    return "This plug is currently disconnected. You can book future reservation or try again later.";
  } else if (plugStatus == "Charging") {
    return "There is an active charging session for this plug. You can book a future reservation or try again later.";
  } else {
    return "This plug does not support the option to instant charge. You can book a future reservation instead.";
  }
};
/**
 * Returns the requires_approval field of a given station
 */

export const requiresApproval = (stations, selectedPlugId, stationSlug) => {
  return stations.filter(station => station.slug == stationSlug).map(station => station.plugs)[0].filter(plugs => plugs.id == selectedPlugId)[0]["requires_approval"];
};
/**
 * @deprecated
 * @param {*} userReservations 
 * @param {*} selectedPlugId 
 * @returns 
 */

export const hasUpcomingReservation = (userReservations, selectedPlugId) => {
  return userReservations.filter(reservation => reservation.plug.id == selectedPlugId).filter(reservation => reservation.stop_time > moment().format()).filter(reservation => reservation.start_time < moment().add(15, 'minutes').format()).filter(reservation => reservation.status !== "CANCELLED").length != 0;
};
/**
 * 
 * @param {Object} reservation 
 */

export const reservationIsForNow = reservation => {
  const start_time = new Date(reservation.start_time);
  const stop_time = new Date(reservation.stop_time);
  const now = new Date();

  if (start_time - now <= 0 && stop_time - now >= 0) {
    return true;
  } else {
    return false;
  }
};
/**
 * Checks if a reservation is in the past
 * @param {Object} reservation 
 * @returns boolean
 */

export const reservationHasPassed = reservation => {
  const start_time = new Date(reservation.start_time);
  const stop_time = new Date(reservation.stop_time);
  const now = new Date();

  if (start_time - now <= 0 && stop_time - now <= 0) {
    return true;
  } else {
    return false;
  }
};
/**
 * Takes the key from reservations from frontend and translates to the
 * sortable key of the backend
 * @param {String} reservationKey 
 */

export const reservationsSortingKeys = (reservationKey, reservationOrder) => {
  if (reservationKey == "startDate") {
    if (reservationOrder) return "-start_time";
    return "start_time";
  }

  if (reservationKey == "driverName") {
    if (reservationOrder) return "-user__first_name";
    return "user__first_name";
  }

  if (reservationKey == "consumption") {
    if (reservationOrder) return "-total_kwh";
    return "total_kwh";
  }

  if (reservationKey == "stationName") {
    if (reservationOrder) return "-plug__station";
    return "plug__station";
  }

  if (reservationKey == "cost") {
    if (reservationOrder) return "-fiat_amount";
    return "fiat_amount";
  }
};